import Player from '@vimeo/player';
import gsap from 'gsap';

let container = null;

function init() {
  const url = container.dataset.url;
  if (!url) return;
  const video = new Player(container, {
    url: url,
    background: true,
    transparent: false,
    byline: false,
    title: false,
  });

  video.on('play', function() {
    const image = document.getElementById('hero-video-image');
    if (!image) return;
    gsap.to(image, {
      opacity: 0,
      duration: 0.1
    });
  });
}

export default function() {
  container = document.getElementById('video-hero-container');
  if (!container) {
    return;
  }
  init();
}